export const getFutureDate = () => {
    let futureDate = new Date()
    futureDate.setDate(futureDate.getDate() + 182)
    return futureDate
}

// export const formatDates = (dates) => {
//     let arrayDates = []
//     dates.forEach(e => {
//         let date = new Date(e.date)
//         let dateObj = { //OBJs that get passed to datesArray
//             dayName: DayAsString(date.getDay()),
//             dayNumber: date.getDate(),
//             monthNumber: parseInt(MonthNumber(date.getMonth())),
//             monthName: MonthAsString(date.getMonth()),
//             year: date.getFullYear(),
//             delivery: false,
//             pickup: false,
//             fullDate: date,
//             available: e.slots_available > 0 ? true : false,
//         }
//         arrayDates.push(dateObj)
//     })
//     // temp fix, new Date is converting the date to previous day
//     arrayDates.shift()
//     return arrayDates
// }
export const formatDates = (dates, holidays) => {
	let count = 0
    const arrayDates = []

    dates.forEach(e => {
		const tempDate =   new Date(e.date)
		let now_utc = new Date(tempDate.getUTCFullYear(), tempDate.getUTCMonth(),
		tempDate.getUTCDate(), tempDate.getUTCHours(),
		tempDate.getUTCMinutes(), tempDate.getUTCSeconds());

        let date = new Date(now_utc)

        const dateObj = {
            dayName: DayAsString(now_utc.getDay()),
            dayNumber: now_utc.getDate(),
            monthNumber: parseInt(MonthNumber(now_utc.getMonth())),
            monthName: MonthAsString(now_utc.getMonth()),
            year: now_utc.getFullYear(),
            delivery: false,
            pickup: false,
            fullDate: date,
			stringDate: e.date,
			afternoonAvailable: e.afternoon_available,
			morningAvailable: e.morning_available,
            available: e.slots_available > 0 ? true : false,
        }
		if(count > 60){
			dateObj.available = false
		}
		count++
        arrayDates.push(dateObj)
    })
	for (let j = 0; j < holidays.length; j++) { //restricts certain dates
		let holiday = holidays[j].holiday_date
		if(holiday && typeof holiday === 'string'){

			for (let k = 0; k < arrayDates.length; k++) {
				const tempDate = arrayDates[k]
				if(tempDate.stringDate.slice(0,10) === holiday.slice(0,10)){
					console.log(	arrayDates[k])
					arrayDates[k].available = false
				}
			}

		}
	}
	console.log('dates: ', arrayDates)
    return arrayDates
}
export const GetDates = (startDate, daysToAdd) => {
    let arrayDates = []
    Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }

    function getDates(startDate, stopDate) {
        let dateArray = new Array()
        let currentDate = startDate
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate))
            currentDate = currentDate.addDays(1)
        }
        return dateArray
    }
    let dateArray = getDates(startDate, (new Date(startDate)).addDays(daysToAdd))

    dateArray.forEach(e => {
        let dateObj = { //OBJs that get passed to datesArray
            dayName: DayAsString(e.getDay()),
            dayNumber: e.getDate(),
            monthNumber: parseInt(MonthNumber(e.getMonth())),
            monthName: MonthAsString(e.getMonth()),
            year: e.getFullYear(),
            delivery: false,
            pickup: false,
            fullDate: new Date(e),
            available: true,
        }
        arrayDates.push(dateObj)
    })
    return arrayDates
}
export const MonthNumber = (monthIndex) => {
    let monthNumber = new Array()
    monthNumber[0] = "1"
    monthNumber[1] = "2"
    monthNumber[2] = "3"
    monthNumber[3] = "4"
    monthNumber[4] = "5"
    monthNumber[5] = "6"
    monthNumber[6] = "7"
    monthNumber[7] = "8"
    monthNumber[8] = "9"
    monthNumber[9] = "10"
    monthNumber[10] = "11"
    monthNumber[11] = "12"

    return monthNumber[monthIndex]
}

export const MonthAsString = (monthIndex) => {
    let month = new Array()
    month[0] = "Jan"
    month[1] = "Feb"
    month[2] = "Mar"
    month[3] = "Aprl"
    month[4] = "May"
    month[5] = "Jun"
    month[6] = "Jul"
    month[7] = "Aug"
    month[8] = "Sep"
    month[9] = "Oct"
    month[10] = "Nov"
    month[11] = "Dec"

    return month[monthIndex]
}

export const DayAsString = (dayIndex) => {
    let weekdays = new Array(7)
    weekdays[0] = "Sunday"
    weekdays[1] = "Monday"
    weekdays[2] = "Tuesday"
    weekdays[3] = "Wednesday"
    weekdays[4] = "Thursday"
    weekdays[5] = "Friday"
    weekdays[6] = "Saturday"

    return weekdays[dayIndex]
}

export const formatDateTime = (selectedTime, selectedDay) => {
    let containsPM = /pm/gi.test(selectedTime?.hours)
    let timeOffSet = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]
    let firstNum = selectedTime?.hours.match(/[^\:]+/) ? selectedTime.hours.match(/[^\:]+/)[0] : '00'
    let num = (containsPM ? (parseInt(firstNum) + 12)  : firstNum)
    let timeAndDay = selectedDay.year + '-' + selectedDay.monthNumber + '-' + selectedDay.dayNumber + ' ' + num + ':00:00' +( '-'+timeOffSet.replace(/\D/g, ''))
    return timeAndDay
}

export const formatTimeSlots = (timeslots) => {
    let results = []
    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + '' + ampm;
        return strTime;
    }
    const filterTimeSlots = (slots) =>{
        if(slots.length > 1){
            return slots.sort((a, b) => {
                const aStartHour = a.start_hour
                const bStartHour = b.start_hour
                const aStartMinute = a.start_minute
                const bStartMinute = b.start_minute
                if (aStartHour < bStartHour) {
                    if (aStartMinute <= bStartMinute) {
                        return -1;
                    }
                  return -1;
                }
                if (aStartHour > bStartHour) {
                  return 1;
                }
                return 0;
              });
        }
        else {
            return slots
        }
    }
    let slots = filterTimeSlots(timeslots)
    slots.forEach(timeslot => {
        let startTime = new Date()
        let endTime = new Date()
        if(typeof timeslot.start_hour === 'number' && typeof timeslot.start_minute === 'number' && typeof timeslot.end_hour === 'number' && typeof timeslot.end_minute === 'number'){
            startTime.setHours(timeslot.start_hour)
            startTime.setMinutes(timeslot.start_minute)
            endTime.setHours(timeslot.end_hour)
            endTime.setMinutes(timeslot.end_minute)

            results.push({
                hours: formatAMPM(startTime) + '-' +formatAMPM(endTime),
                timeOfDay: timeslot.start_hour < 12 ? 'MORNING' : 'AFTERNOON',
                checked: false,
                delivery: false,
                pickup: false,
                start_hour: timeslot.start_hour,
                end_hour: timeslot.end_hour,
                start_minute: timeslot.start_minute,
                end_minute: timeslot.end_minute,
            })
        }
    })
    return results
}
export const convertPreferredTime = (preferred_time) => {
    // let getNumbers = preferred_time.match(/[0-9]+/g)
    // let AMPM =preferred_time.match(/am|pm/gi)
    // let start_hour = getNumbers[0] ?  getNumbers[0] :  '09'
    // let end_hour = getNumbers[2] ?  getNumbers[2] :  '12'
    // let start_minute = getNumbers[1] ?  getNumbers[1] :  '00'
    // let end_minute = getNumbers[3] ?  getNumbers[3] :  '00'
    // if(AMPM[0] && /pm/i.test(AMPM[0])){
    //     start_hour = (parseInt(start_hour) + 12).toString()
    // }
    // if(AMPM[1] && /pm/i.test(AMPM[1])){
    //     end_hour = (parseInt(end_hour) + 12).toString()
    // }
    // return start_hour.padStart(2, '0') + ':' + start_minute + '-' + end_hour.padStart(2, '0') +  ':' + end_minute
    return preferred_time
}
