// import router from "../../router/index"
// https://dev.api.rapidrent.com/venues?zip=75075
import { getFutureDate, formatDates, GetDates, MonthNumber, MonthAsString, DayAsString, formatDateTime, formatTimeSlots, convertPreferredTime } from "../helpers/Dates"
import { formatRental, formatBundlePrice } from "../helpers/Bundles"
import axios from 'axios'
export const namespaced = true

// STATE
export const state = {
    initialLoad: false,
    currentLocation: {
        zipcode: null //LOCATION FOR API REQUEST
    },
    currentIndex: -1,
    apiData: null,
    dataRes: null,
    confirmation: null,
    cartItems: [], //CART
    customer: {},
    tabs: (location.hostname.includes('rugdoctordelivery.com') || location.hostname.includes('delivery.rugdoctor.com') || location.hostname.includes('rugdoctorhomedelivery')) ? [
        { name: 'Schedule delivery', href: 'delivery', current: true },
        { name: 'Schedule pickup', href: 'pickup', current: false },
        { name: 'Choose a product', href: 'bundles', current: false },
        { name: 'Other Featured Items', href: 'sellables', current: false },
        { name: 'Checkout', href: 'checkout', current: false },
    ] :
        [
            { name: 'Schedule reservation', href: 'dates', current: true },
            { name: 'Choose a product', href: 'products', current: false },
            { name: 'Checkout', href: 'checkout', current: false },
        ],
    currentTabIndex: 0,
    selectionForm: { // FORM
        delivery: { // For Reservations delivery will be the reservation day selected
            day: null,
            time: null,
        },
        pickup: {
            day: null,
            time: null,
        },
        productSelected: {
            machine: null,
        }
    },
    deliveryDay: null,
    pickupDay: null,
    timeOfDayOptions: [
        { timeOfDay: 'MORNING', hours: '8:00am-12:00pm', checked: false, delivery: false, pickup: false },
        { timeOfDay: 'AFTERNOON', hours: '1:00pm-5:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'MORNING', hours: '8:00am-10:00am', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'MORNING', hours: '10:00am-12:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'AFTERNOON', hours: '1:00pm-3:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'AFTERNOON', hours: '3:00pm-5:00pm', checked: false, delivery: false, pickup: false },
    ],
    pickupTimeOfDayOptions: [
        { timeOfDay: 'MORNING', hours: '8:00am-12:00pm', checked: false, delivery: false, pickup: false },
        { timeOfDay: 'AFTERNOON', hours: '1:00pm-5:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'MORNING', hours: '8:00am-10:00am', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'MORNING', hours: '10:00am-12:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'AFTERNOON', hours: '1:00pm-3:00pm', checked: false, delivery: false, pickup: false },
        // { timeOfDay: 'AFTERNOON', hours: '3:00pm-5:00pm', checked: false, delivery: false, pickup: false },
    ],

    datesArray: [], //DATES
    pickupDatesArray: [],
    calendarToggle: false,
    machineList: [],
    toolList: [],
    solutionList: [],
    bundleList: [],
    productPageErrorMsg: null,
    checkoutPageErrorMsg: null,
    couponErrorMsg: null,
    reservationsErrorMsg: null,
    deliveryErrorMsg: null,
    updateOrderError: null,
    jdeNumber: null,
    kiosk_id: null,
    affiliate_id: null,
    skuPriceMatrix: {},
    holidays: [],
    timeslots: [],
}

export const mutations = {
    //API
    SET_AFFILIATE(state, id) {
        state.affiliate_id = id
    },
    SET_APIDATES(state, data) {
        state.apiDates = data
    },
    SET_APIFORM(state, data) {
        state.dataRes = data
    },
    FILTER_APIDATA(state, resp) { // Home Delivery
        state.deliveryErrorMsg = null
        let data = JSON.parse(JSON.stringify(resp))
        if (data) {
            // Reset state
            state.machineList = []
            state.solutionList = []
            state.toolList = []
            state.selectionForm.productSelected.machine = null
            state.cartItems = []
            state.bundleList = []

            data.products_available_at_zip.forEach(e => {
                const machineType = /machine/gi
                const solutionType = /solution/gi
                const attachmentType = /attachment/gi
                if (e.active === true && e.sku_number) {
                    if (machineType.test(e.product_group)) { // machines

                        e.price = e.price.retail_price
                        state.machineList.push(e)
                        if (/bundle/gi.test(e.name) && typeof e.description_3 === 'string' && e.description_3.length > 1) {
                            let sku_numbers = e.description_3.match(/\d+/g) ?? []
                            e.sku_numbers = sku_numbers
                            e.showBundle = false
                            state.bundleList.push(e)
                        }
                        state.skuPriceMatrix[e.sku_number] = e.price
                    }
                    else if (solutionType.test(e.product_group)) { //cleaning supplies

                        e.price = e.price.retail_price
                        state.solutionList.push(e)
                        state.skuPriceMatrix[e.sku_number] = e.price
                    }
                    else if (attachmentType.test(e.product_group)) { //tools

                        e.price = e.price.retail_price
                        state.toolList.push(e)
                        state.skuPriceMatrix[e.sku_number] = e.price
                    }
                }
            })
            for (let i = 0; i < state.bundleList.length; i++) {
                let bundle = state.bundleList[i]
                let skuCount = bundle.sku_numbers.length
                let price = 0
                for (let j = 0; j < bundle.sku_numbers.length; j++) {
                    let sku = bundle.sku_numbers[j]
                    if (!state.skuPriceMatrix[sku]) {
                        break
                    }
                    else {
                        price += (state.skuPriceMatrix[sku] || 0)
                        if ((j + 1) === skuCount) {
                            bundle.showBundle = true
                            bundle.price = price
                            console.log(bundle)
                        }
                    }
                }
            }
            state.apiData = data.products_available_at_zip

        }
    },
    FILTER_RESERVATION_DATA(state, resp) { // Reservations
        this.reservationsErrorMsg = null
        let data = JSON.parse(JSON.stringify(resp))
        if (data[0]) {
            // Reset state
            state.selectionForm.productSelected.machine = null
            state.cartItems = []
            state.jdeNumber = data.jde_number
            const machineList = []
            const toolList = []
            let kiosk_id = data[0].id ? data[0].id : null
            const availableBays = data[0].bays.filter(bay => bay.products_in_bay && bay.state === 'available')
            if (availableBays.length > 0) {
                availableBays.forEach(bay => {
                    if (bay.state === 'available' && Array.isArray(bay.products_in_bay) && bay.occupancy > 0) {
                        bay.products_in_bay.forEach(product => {
                            let productObj = { ...product.product_type }
                            let price = product?.price?.retail_price
                            if (product.product_type?.product_group === "machine" && bay.bay_available_dates.length > 0) { // machines
                                productObj.bayNumber = bay.number
                                productObj.price = price ? price : product.product_type?.base_price?.retail_price
                                productObj.bay_id = bay.id
                                productObj.kiosk_id = kiosk_id
                                productObj.bay_available_dates = bay.bay_available_dates
                                machineList.push(productObj)
                            }
                            else if (product.product_type?.product_group === "attachment") { //tools
                                productObj.bayNumber = bay.number
                                productObj.price = price ? price : product.product_type?.base_price?.retail_price
                                productObj.kiosk_id = kiosk_id
                                toolList.push(productObj)
                            }
                        })
                    }
                })
            }
            state.machineList = machineList.reduce((acc, current) => { // removes duplicates
                const x = acc.find(item => item.id === current.id)
                if (!x) {
                    return acc.concat([current])
                } else {
                    return acc
                }
            }, [])
            state.toolList = toolList.reduce((acc, current) => { // removes duplicates
                const x = acc.find(item => item.id === current.id)
                if (!x) {
                    return acc.concat([current])
                } else {
                    return acc
                }
            }, [])

            if (machineList.length === 0) {
                state.reservationsErrorMsg = 'No rentals available at this time.'
            }
            else {
                state.reservationsErrorMsg = null
                state.apiData = data[0]
            }
            state.kiosk_id = data[0].id
            // state.apiData = data.products_available_at_zip
        }
    },
    FILTER_ADDONS(state, resp) { // Reservations
        let data = JSON.parse(JSON.stringify(resp))
        const solutionList = []
        if (Array.isArray(data) && data.length > 0) {
            data.forEach(product => {
                if (product.product_group === "solution" && product.price?.retail_price) {
                    product.price = product.price?.retail_price
                    solutionList.push(product)
                }
            })
        }
        state.solutionList = solutionList
    },
    FILTER_BUNDLES(state, resp) { // Reservations
        let data = JSON.parse(JSON.stringify(resp))
        const bundleList = []
        if (Array.isArray(data) && data.length > 0) {
            data.forEach(product => {
                // if(product.product_group === "solution" && product.price?.retail_price){
                //     product.price = product.price?.retail_price
                //     bundleList.push(product)
                // }
            })
        }
        state.bundleList = bundleList
    },
    SET_HOLIDAYS(state, resp) {
        let data = JSON.parse(JSON.stringify(resp))
        const holidayList = []
        if (Array.isArray(data) && data.length > 0) {
            data.forEach(d => {
                if (d.holiday_date) {
                    d.fullDate = new Date(d.holiday_date)
                    holidayList.push(new Date(d.fullDate.toISOString().slice(0, -1)))
                }
            })
        }
        state.holidays = holidayList
    },
    SET_TIME_SLOTS(state, resp) {
        let data = JSON.parse(JSON.stringify(resp))
        let timeslotsList = []
        if (Array.isArray(data) && data.length > 0) {
            timeslotsList = formatTimeSlots(data)
            state.timeOfDayOptions = timeslotsList
            state.pickupTimeOfDayOptions = timeslotsList
        }
        state.timeslots = timeslotsList
    },
    SET_CONFIRMATION(state, data) {
        state.confirmation = data
    },
    SET_ERROR_MSG(state, obj) {
        state[obj.page] = obj.message
    },
    SET_COUPON_MSG(state, message) {
        state.couponErrorMsg = message
    },
    //CUSTOMER
    SET_CUSTOMER(state, customer) {
        state.customer = customer
    },
    //TAB CONTROL
    SET_TABS(state, tabs) {
        state.tabs = tabs
    },
    SET_CURRENTINDEX(state, currentIndex) {
        state.currentIndex = currentIndex
    },

    //FORM
    SET_FORM(state, selectionForm) {
        state.selectionForm = selectionForm
    },
    RESET_FORM(state) {
        state.selectionForm = {
            delivery: {
                day: null,
                time: null,
            },
            pickup: {
                day: null,
                time: null,
            },
            productSelected: {
                machine: null,
            }
        }
        state.cartItems = []
        state.pickupDatesArray = []
        state.calendarToggle = false
        state.apiData = null
        state.dataRes = null
        state.confirmation = null
            //Resets pickup & delivery
            state.datesArray.forEach(item => {
                item.delivery = false
                item.pickup = false
            })
        state.timeOfDayOptions[0].delivery = false
        state.timeOfDayOptions[1].delivery = false
        state.timeOfDayOptions[0].pickup = false
        state.timeOfDayOptions[1].pickup = false

        state.productPageErrorMsg = null,
            state.checkoutPageErrorMsg = null,
            state.datesArray = [],
            state.confirmation = null
    },

    // CART ACTIONS
    ADD_ITEM(state, newItem) {
        const checkedItemIndex = state.cartItems.indexOf(state.cartItems.find(item => item.name === newItem.name))
        if (checkedItemIndex >= 0) { // item is found
            state.cartItems[checkedItemIndex].quantity += 1
        }
        else {
            if (newItem.quantity == 0 || newItem.quantity == undefined) {
                newItem.quantity = 1
            }
            state.cartItems.push(newItem)
        }
    },
    DEL_ITEM(state, item) {
        const itemIndex = state.cartItems.indexOf(state.cartItems.find(e => e.name === item.name))
        const solutionListIndex = state.solutionList.indexOf(state.solutionList.find(e => e.name === item.name))
        if (itemIndex >= 0) { // item is found
            if (state.cartItems[itemIndex].quantity === 1) {
                state.cartItems[itemIndex].quantity = 0
                state.cartItems.splice(itemIndex, 1)
                if (solutionListIndex > -1) {
                    state.solutionList[solutionListIndex].quantity = 0
                }
            } else {
                state.cartItems[itemIndex].quantity -= 1
                if (solutionListIndex > -1) {
                    state.solutionList[solutionListIndex].quantity = state.cartItems[itemIndex].quantity
                }
            }
        }
    },
    SET_MACHINE(state, machine) {
        state.selectionForm.productSelected.machine = machine
        state.cartItems = []
        state.cartItems.unshift(machine)
        //
    },
    DESELECT_MACHINE(state, machine) {
        const prevMachine = state.machineList.indexOf(state.machineList.find(e => e.selected === true))
        if (prevMachine !== undefined) {
            if (state.machineList[prevMachine].selected) {
                state.machineList[prevMachine].selected = false
            }

            //find item in cart and removes
            state.solutionList.forEach(item => item.quantity = 0)
            state.cartItems = []
            // const itemIndex = state.cartItems.indexOf(state.cartItems.find(e => e.name === machine.name))
            // state.cartItems.splice(itemIndex, 1)
        }
    },
    DESELECT_TOOL(state, tool) {

        const toolIndex = state.toolList.indexOf(state.toolList.find(e => e.id === tool.id))
        if (toolIndex !== undefined) {
            if (state.toolList[toolIndex].selected) {
                state.toolList[toolIndex].selected = false
            }

            //find item in cart and removes
            const itemIndex = state.cartItems.indexOf(state.cartItems.find(e => e.id === tool.id))
            state.cartItems.splice(itemIndex, 1)

        }
    },

    //DATES
    SET_TIMEOFDAY(state, time) {

        if (time.scheduledDay === 'delivery') {
            state.selectionForm.delivery.time = time
            state.timeOfDayOptions[0].delivery = false
            state.timeOfDayOptions[1].delivery = false

            //Resets pickup
            state.timeOfDayOptions[0].pickup = false
            state.timeOfDayOptions[1].pickup = false
            state.selectionForm.pickup.day = null
            state.selectionForm.pickup.time = null
            state.pickupDatesArray.forEach(item => item.pickup = false)
            //[0] = morning, [1] = afternoon
            if (time.timeOfDay.toLowerCase() === "morning") {
                state.timeOfDayOptions[0].delivery = true
            } else {// "afternoon"
                state.timeOfDayOptions[1].delivery = true
            }

        }
        else { //"pickup"
            state.selectionForm.pickup.time = time
            state.timeOfDayOptions[0].pickup = false
            state.timeOfDayOptions[1].pickup = false
            if (time.timeOfDay.toLowerCase() === "morning") {
                state.timeOfDayOptions[0].pickup = true
            } else {// "afternoon"
                state.timeOfDayOptions[1].pickup = true
            }
        }
    },
    SET_DAY(state, day) {

        if (day.scheduledDay === 'delivery') {
            const deliveryCheckedItems = state.datesArray.filter(item => item.delivery === true)
            const findDay = state.datesArray.find(item => item.fullDate === day.fullDate)
            if (deliveryCheckedItems.length > 0) {
                deliveryCheckedItems.forEach(e => e.delivery = false)
            }
            findDay.delivery = true
            state.selectionForm.delivery.day = findDay
            // //Resets pickup
            state.pickupDatesArray.forEach(item => item.pickup = false)
            state.timeOfDayOptions[0].pickup = false
            state.timeOfDayOptions[1].pickup = false
            state.selectionForm.pickup.day = null
            state.selectionForm.delivery.time = null
            state.selectionForm.pickup.time = null
            state.timeOfDayOptions[0].delivery = false
            state.timeOfDayOptions[1].delivery = false
            state.timeOfDayOptions[0].pickup = false
            state.timeOfDayOptions[1].pickup = false
            // calendar highlight off
            state.calendarToggle = false
        }
        else { //"pickup"
            const pickupCheckedItems = state.pickupDatesArray.filter(item => item.pickup === true)
            const findDay = state.pickupDatesArray.find(item => item.fullDate === day.fullDate)
            if (pickupCheckedItems.length > 0) {
                pickupCheckedItems.forEach(e => e.pickup = false)
            }
            findDay.pickup = true
            state.selectionForm.pickup.day = findDay
        }
    },
    SET_DAY_CALENDAR(state, day) { //Sets date from calendar

        let selectedDate = new Date(day)
        let dateObj = { //OBJs that get passed to datesArray
            dayName: DayAsString(selectedDate.getDay()),
            dayNumber: selectedDate.getDate(),
            monthNumber: parseInt(MonthNumber(selectedDate.getMonth())),
            monthName: MonthAsString(selectedDate.getMonth()),
            year: selectedDate.getFullYear(),
            delivery: false,
            pickup: false,
            fullDate: selectedDate
        }

        state.selectionForm.delivery.day = dateObj
        //Resets pickup & delivery
        state.datesArray.forEach(item => {
            item.delivery = false
            item.pickup = false
        })
        state.pickupDatesArray.forEach(item => {
            item.delivery = false
            item.pickup = false
        })
        const findDay = state.datesArray.find(item => item.dayNumber === dateObj.dayNumber && item.monthNumber === dateObj.monthNumber && item.year === dateObj.year)
        if (findDay) {
            findDay.delivery = true
        }
        state.timeOfDayOptions[0].pickup = false
        state.timeOfDayOptions[1].pickup = false
        state.selectionForm.pickup.day = null
        state.selectionForm.pickup.time = null
        state.selectionForm.delivery.time = null
        state.timeOfDayOptions[0].delivery = false
        state.timeOfDayOptions[1].delivery = false
        state.timeOfDayOptions[0].pickup = false
        state.timeOfDayOptions[1].pickup = false
        // calendar highlight on

        let currentIndex = state.datesArray.findIndex(item => item.dayNumber === dateObj.dayNumber && item.monthNumber === dateObj.monthNumber && item.year === dateObj.year)
        if (currentIndex) {
            state.currentIndex = currentIndex
        }

        state.calendarToggle = true

    },
    SET_WEEK(state, dates) { //Sets 7 days
        // let startDate = new Date()
        // state.datesArray = GetDates(startDate, 6)
        // dates[6].available = false //test
        state.datesArray = dates

    },
    SET_RESERVATION_WEEK(state, dayLimit) { //Sets 7 days
        state.datesArray = GetDates(new Date(), dayLimit)
    },
    SET_PICKUP_WEEK(state, day) { //Sets 2 days from date
        let isObj = typeof day
        let startDate

        if (isObj === "object") {
            if (day.year) { //User Clicks 1 of the 7 days
                startDate = new Date(day.fullDate)
            }
            else { //User clicks a date on calendar
                startDate = new Date(day)
            }
        }
        else {
            startDate = new Date()
        }
        // startDate.setDate(startDate.getDate() - 1)
        // state.pickupDatesArray = GetDates(startDate, 3)
        startDate.setDate(startDate.getDate())
        state.pickupDatesArray = GetDates(startDate, 1)

    },
    RESET_DATES(state) {
        state.timeOfDayOptions[0].pickup = false
        state.timeOfDayOptions[1].pickup = false
        state.selectionForm.pickup.day = null
        state.selectionForm.pickup.time = null

        state.timeOfDayOptions[0].delivery = false
        state.timeOfDayOptions[1].delivery = false
        state.selectionForm.delivery.day = null
        state.selectionForm.delivery.time = null
    },
    SET_CALENDAR_TOGGLE(state) {
        state.calendarToggle = !state.calendarToggle
    },

    SET_ZIPCODE(state, zipcode) {
        state.currentLocation.zipcode = zipcode
    },
    SET_INITIALLOAD(state, zipcode) {
        state.initialLoad = true
    },
    SET_CIRRENTTABINDEX(state, index) {
        state.currentTabIndex = index
    }
}

export const actions = {
    //ZipCode
    async setZipcode({ commit, dispatch }, zipcode) {
        commit("SET_ZIPCODE", zipcode)
    },
    async setInitialLoad({ commit, dispatch }) {
        commit("SET_INITIALLOAD")
    },
    //CART
    async addItem({ commit, dispatch }, item) {
        commit("ADD_ITEM", item)
    },
    async delItem({ commit, dispatch }, item) {
        commit("DEL_ITEM", item)
    },
    async setMachine({ commit, dispatch }, machine) {
        commit("SET_MACHINE", machine)
    },
    async deselectMachine({ commit, dispatch }, machine) {
        commit("DESELECT_MACHINE", machine)
    },
    async deselectTool({ commit, dispatch }, tool) {
        commit("DESELECT_TOOL", tool)
    },

    //TABS
    async setTabs({ commit, dispatch }, tabs) {
        commit("SET_TABS", tabs)
    },

    async setCurrentTabIndex({ commit, dispatch }, index) {
        commit("SET_CIRRENTTABINDEX", index)
    },
    //FORM
    async setForm({ commit, dispatch }, selectionForm) {
        commit("SET_FORM", selectionForm)
    },
    async resetForm({ commit, dispatch }) {
        commit("RESET_FORM")
    },
    async setAffiliate({ commit, dispatch }, id) {
        commit("SET_AFFILIATE", id)
    },

    //DATES
    async setTimeOfDay({ commit, dispatch }, time) {
        commit("SET_TIMEOFDAY", time)
    },
    async setDay({ commit, dispatch }, day) {
        commit('SET_DAY', day)
    },
    async setDayCalendar({ commit, dispatch }, day) {
        commit('SET_DAY_CALENDAR', day)
    },
    async setWeek({ commit }) {
        commit('SET_WEEK')
    },
    async setPickupWeek({ commit, dispatch }, day) {
        commit('SET_PICKUP_WEEK', day)
    },
    async resetDates({ commit }) {
        commit('RESET_DATES')
    },
    async setCalendarToggle({ commit }) {
        commit('SET_CALENDAR_TOGGLE')
    },
    async setReservationWeek({ commit }, dayLimit) {
        commit('SET_RESERVATION_WEEK', dayLimit)
    },
    //API
    async setApiData({ commit, dispatch }, data) {
        commit('SET_APIDATA', data)
    },
    async getDates({ commit, dispatch }, zip) { //Home Delivery

        const today = new Date()
        let startD = new Date()
        let futureDate = new Date()
        futureDate.setDate(futureDate.getDate() + 182)
        const zipcode = zip ? zip : state.zipcode

        const startDate = startD.getUTCFullYear() + '-' + (startD.getUTCMonth() + 1) + '-' + startD.getUTCDate()
        const endDate = futureDate.getUTCFullYear() + '-' + (futureDate.getUTCMonth() + 1) + '-' + futureDate.getUTCDate()

        const apiUrl1 = process.env.MIX_API_URL + '/drivers/driver-availability?' + 'start_date=' + startDate + '&due_date=' + endDate + ' &renter_zip=' + zipcode
        const apiUrl2 = process.env.MIX_API_URL + '/venues/products-available-at-zip?renter_zip=' + zipcode
        const apiUrl3 = process.env.MIX_API_URL + '/holiday/holidays-by-zip?zip=' + zipcode + '&start_date=' + startDate + '&end_date=' + endDate
        const requestOne = axios({
            url: apiUrl1,
            method: 'get',
            timeout: 100000,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const requestTwo = axios({
            url: apiUrl2,
            method: 'get',
            timeout: 100000,
            headers: {
                'Content-Type': 'application/json',
            }
        })

        const requestThree = axios({
            url: apiUrl3,
            method: 'get',
            timeout: 100000,
            headers: {
                'Content-Type': 'application/json',
            }
        })

        axios.all([requestOne, requestTwo, requestThree]).then(axios.spread((...responses) => {

            const responseOne = responses[0]
            const responseTwo = responses[1]
            const responseThree = responses[2]

            if (responseOne?.data && responseTwo?.data && responseThree?.data) {

                const slots = responseOne.data
                const items = responseTwo.data
                const holidayDates = responseThree.data

                if (Array.isArray(items?.products_available_at_zip) && items?.products_available_at_zip.length > 0 && Array.isArray(slots) && slots.length > 0) {
                    let datesArray = formatDates(slots, holidayDates)

                    commit("SET_ZIPCODE", zipcode)
                    commit('FILTER_APIDATA', items)
                    commit('SET_APIDATES', datesArray)

                    commit('SET_HOLIDAYS', holidayDates)
                    commit('SET_WEEK', datesArray)
                }
                else {
                    commit('SET_ERROR_MSG', { // Resets msg
                        message: 'Sorry, Home Delivery service is not supported in your area.',
                        page: "deliveryErrorMsg",
                    })
                }
            }
            else {
                commit('SET_ERROR_MSG', { // Resets msg
                    message: 'Sorry, Home Delivery service is not supported in your area.',
                    page: "deliveryErrorMsg",
                })
            }
        })).catch(errors => {
            console.log(errors)
            commit('SET_ERROR_MSG', { // Resets msg
                message: 'Sorry, Home Delivery service is not supported in your area.',
                page: "deliveryErrorMsg",
            })
        })

    },
    // async getDates({ commit, dispatch }, zip) { //Home Delivery
    //     let today = new Date()
    //     let sixMonthOut = getFutureDate()
    //     let startDate = today.getUTCFullYear() + '-' + (today.getUTCMonth() + 1) + '-' + today.getUTCDate()
    //     let endDate = sixMonthOut.getUTCFullYear() + '-' + (sixMonthOut.getUTCMonth() + 1) + '-' + sixMonthOut.getUTCDate()
    //     let apiUrl = process.env.MIX_API_URL + '/drivers/driver-availability?'
    //     let zipcode = zip ? zip : state.currentLocation.zipcode
    //     try {
    //         let res = await axios({

    //             url: apiUrl + 'start_date=' + startDate + '&due_date=' + endDate + ' &renter_zip=' + zipcode,
    //             method: 'get',
    //             timeout: 100000,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             }
    //         })
    //         if (res.status == 200) {
    //             // console.log(res.status)
    //         }
    //         if (res?.data) {
    //             commit('SET_APIDATES', res.data)
    //             commit('SET_WEEK', formatDates(res.data))
    //         }
    //     }
    //     catch (err) {
    //         console.error(err)
    //     }
    // },
    async getData({ commit, dispatch }, zip) { //Home Delivery
        commit('SET_ERROR_MSG', { // Resets msg
            message: null,
            page: "deliveryErrorMsg",
        })
        let apiUrl = process.env.MIX_API_URL + '/venues/products-available-at-zip?renter_zip='
        let zipcode = zip ? zip : state.currentLocation.zipcode
        try {
            let res = await axios({

                url: apiUrl + zipcode,
                method: 'get',
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (res.status == 200) {
                // console.log(res.status)
            }
            if (Array.isArray(res.data?.products_available_at_zip) && res.data.products_available_at_zip.length > 0) {
                commit('FILTER_APIDATA', res.data)
            }
            else {
                commit('SET_ERROR_MSG', { // Resets msg
                    message: 'Sorry, Home Delivery service is not supported in your area.',
                    page: "deliveryErrorMsg",
                })
            }


        }
        catch (err) {
            console.error(err)
            commit('SET_ERROR_MSG', { // Resets msg
                message: 'Sorry, Home Delivery service is not supported in your area.',
                page: "deliveryErrorMsg",
            })
        }
    },
    async getReservationData({ commit, dispatch }, jdeNumber) { //Reservations
        commit('SET_ERROR_MSG', { // Resets msg
            message: null,
            page: "reservationsErrorMsg",
        })
        const apiUrl = process.env.MIX_API_URL + '/kiosks/?jde_number=' + jdeNumber
        try {
            let res = await axios({

                url: apiUrl,
                method: 'get',
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (res.status == 200) {
                // console.log(res.status)
            }
            if (res.data[0]?.reservation_support === true) {
                commit('FILTER_RESERVATION_DATA', res.data)
            }
            else {
                commit('SET_ERROR_MSG', {
                    message: 'This JDE does not support reservations',
                    page: "reservationsErrorMsg",
                })
            }
            if (res.data[0]?.id && res.data[0].chemicals_sell === true && res.data[0]?.reservation_support === true) { //gets solutions
                const solutionsUrl = process.env.MIX_API_URL + '/product_types?' + 'kiosk_id='
                try {
                    let solutionResponse = await axios({
                        url: solutionsUrl + res.data[0].id,
                        method: 'get',
                        timeout: 100000,
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    commit('FILTER_ADDONS', solutionResponse.data)
                }
                catch (err) {
                    console.error(err)
                    commit('SET_ERROR_MSG', {
                        message: 'This JDE does not support reservations',
                        page: "reservationsErrorMsg",
                    })
                }
            }
            // if(res.data[0]?.id && res.data[0].chemicals_sell === true && res.data[0]?.reservation_support === true){ //gets bundles
            //     const bundlesUrl = process.env.MIX_API_URL + '/bundles?' + 'kiosk_id='
            //     try {
            //         let bundleResponse = await axios({
            //             url: bundlesUrl + res.data[0].id,
            //             method: 'get',
            //             timeout: 100000,
            //             headers: {
            //                 'Content-Type': 'application/json',
            //             }
            //         })
            //         commit('FILTER_BUNDLES', bundleResponse.data)
            //     }
            //     catch (err) {
            //         console.error(err)
            //     }
            // }
        }
        catch (err) {
            if (err.response?.data?.message) {
                let errorObj = {
                    message: err.response.data.message,
                    page: "reservationsErrorMsg",
                }
                commit('SET_ERROR_MSG', errorObj)
                console.error(err)
            }
        }
    },
    async getApiForm({ commit, state }, obj) { // Home Delivery
        commit('SET_ERROR_MSG', {
            message: null,
            page: "updateOrderError",
        })
        let deliveryDay = obj.selectionForm.delivery.day
        let pickupDay = obj.selectionForm.pickup.day
        let deliveryTime = obj.selectionForm.delivery.time
        let pickupTime = obj.selectionForm.pickup.time

        const form = {
            kiosk_id: obj.cart[0].kiosk?.id,
            start_date: formatDateTime(deliveryTime, deliveryDay),
            // end_date: pickupTime.timeOfDay === 'AFTERNOON' ? pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 13:00:00 -0500' : pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 08:00:00 -0500',//"2022-11-22 13:55:14 -0500",
            preferred_delivery_time: convertPreferredTime(deliveryTime.hours),
            preferred_pickup_time: convertPreferredTime(pickupTime.hours),
            coupon_codes: [], //["bundle00428", "12345", "54321"]
            products: [] //[{:product_type_id=>13133, :quantity=>1}, {:product_type_id=>13134, :quantity=>1}]
        }
        form.products = formatRental(obj.cart, state.machineList, state.toolList, state.solutionList)
        // obj.cart.forEach(item => {

        // 	let product = {
        // 		product_type_id: item.id,
        // 		quantity: item.quantity ? item.quantity : 1
        // 	}
        // 	form.products.push(product)
        // })
        let apiUrl = process.env.MIX_API_URL + '/rentals/' //'/reservations/'
        try {
            const resp = await axios.post(apiUrl, form)

            if (resp.status == 200) {
                // console.log(resp.status)
            }
            if (resp?.data) {
                // console.log(resp.data)
                commit('SET_APIFORM', resp.data)
                // console.log('get api form data',resp.data)
            }

        }
        catch (err) {
            console.log(err)
            if (err.response?.data?.message) {

                let errorObj = {
                    message: err.response.data.message,
                    page: "updateOrderError",
                }
                commit('SET_ERROR_MSG', errorObj)
            }
            else {
                let errorObj = {
                    message: "An unknown error has occured",
                    page: "updateOrderError",
                }
                commit('SET_ERROR_MSG', errorObj)
            }
            console.error(err)
        }
    },
    async getReservationsForm({ commit, dispatch }, obj) { // Home Delivery
        commit('SET_ERROR_MSG', {
            message: null,
            page: "updateOrderError",
        })
        commit('SET_ERROR_MSG', {
            message: null,
            page: "SET_COUPON_MSG",
        })
        let deliveryDay = obj.selectionForm.delivery.day

        const form = {
            kiosk_id: obj.kiosk_id,
            start_date: deliveryDay.year + '-' + deliveryDay.monthNumber + '-' + deliveryDay.dayNumber + ' 13:00:00 -0500',
            // end_date: pickupTime.timeOfDay === 'AFTERNOON' ? pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 13:00:00 -0500' : pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 08:00:00 -0500',//"2022-11-22 13:55:14 -0500",
            coupon_codes: [], //["bundle00428", "12345", "54321"]
            products: [] //[{:product_type_id=>13133, :quantity=>1}, {:product_type_id=>13134, :quantity=>1}]
        }
        obj.cart.forEach(item => {

            let product = {
                product_type_id: item.id,
                quantity: item.quantity ? item.quantity : 1
            }
            if (item.bay_id) {
                product.bay_id = item.bay_id
            }
            form.products.push(product)
        })
        let apiUrl = process.env.MIX_API_URL + '/reservations/'
        try {
            const resp = await axios.post(apiUrl, form)

            if (resp.status == 200) {
                // console.log(resp.status)
            }
            if (resp?.data) {
                commit('SET_APIFORM', resp.data)
            }

        }
        catch (err) {
            if (err.response?.data?.message) {

                let errorObj = {
                    message: err.response.data.message,
                    page: "updateOrderError",
                }
                commit('SET_ERROR_MSG', errorObj)
            }
            else {
                let errorObj = {
                    message: "An unknown error has occured",
                    page: "updateOrderError",
                }
                commit('SET_ERROR_MSG', errorObj)
            }
            console.error(err)
        }
    },
    async updateRental({ commit, state }, obj) { //Adds coupons /Updates for Home Delivery
        commit('SET_ERROR_MSG', {
            message: null,
            page: "updateOrderError",
        })
        commit('SET_COUPON_MSG', null)
        let deliveryDay = obj.selectionForm.delivery.day
        let pickupDay = obj.selectionForm.pickup.day
        let deliveryTime = obj.selectionForm.delivery.time
        let pickupTime = obj.selectionForm.pickup.time
        const form = {
            kiosk_id: obj.cart[0].kiosk?.id,
            id: obj.rental_id,
            // start_date: deliveryTime.timeOfDay === 'AFTERNOON' ? deliveryDay.year + '-' + deliveryDay.monthNumber + '-' + deliveryDay.dayNumber + ' 13:00:00 -0600' : deliveryDay.year + '-' + deliveryDay.monthNumber + '-' + deliveryDay.dayNumber + ' 08:00:00 -0600',//"2022-11-22 13:55:14 -0500",
            // end_date: pickupTime.timeOfDay === 'AFTERNOON' ? pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 13:00:00 -0500' : pickupDay.year + '-' + pickupDay.monthNumber + '-' + pickupDay.dayNumber + ' 08:00:00 -0500',//"2022-11-22 13:55:14 -0500",
            start_date: formatDateTime(deliveryTime, deliveryDay),
            preferred_delivery_time: convertPreferredTime(deliveryTime.hours),
            preferred_pickup_time: convertPreferredTime(pickupTime.hours),
            coupon_codes: obj.coupon_codes ? obj.coupon_codes : [], //["bundle00428", "12345", "54321"]
            products: [] //[{:product_type_id=>13133, :quantity=>1}, {:product_type_id=>13134, :quantity=>1}]
        }
        form.products = formatRental(obj.cart, state.machineList, state.toolList, state.solutionList)
        // obj.cart.forEach(item => {

        // 	let product = {
        // 		product_type_id: item.id,
        // 		quantity: item.quantity ? item.quantity : 1
        // 	}
        // 	form.products.push(product)
        // })
        let apiUrl = process.env.MIX_API_URL + '/rentals/' + obj.rental_id
        try {
            const resp = await axios.put(apiUrl, form)

            if (resp.status == 200) {
                // console.log(resp.status)
            }
            if (resp?.data) {
                // console.log(resp.data)
                commit('SET_APIFORM', resp.data)
                // console.log('coupon resp data',resp.data)
            }

        }
        catch (err) {
            console.log('error', err.response?.data?.message)
            let errorMessage = err.response?.data?.message
            if (typeof errorMessage === 'string' && err.response?.data?.message) {
                commit('SET_COUPON_MSG', err.response.data.message,)
            }
            else {
                commit('SET_ERROR_MSG', {
                    message: 'error',
                    page: "updateOrderError",
                })
            }
        }
    },
    async updateReservation({ commit, dispatch }, obj) { //Update Call for Reservations
        commit('SET_ERROR_MSG', {
            message: null,
            page: "updateOrderError",
        })
        commit('SET_COUPON_MSG', null)
        let deliveryDay = obj.selectionForm.delivery.day
        const form = {
            kiosk_id: obj.cart[0].kiosk?.id,
            id: obj.rental_id,
            start_date: deliveryDay.year + '-' + deliveryDay.monthNumber + '-' + deliveryDay.dayNumber + ' 08:00:00 -0600',
            coupon_codes: obj.coupon_codes ? obj.coupon_codes : [], //["bundle00428", "12345", "54321"]
            products: [] //[{:product_type_id=>13133, :quantity=>1}, {:product_type_id=>13134, :quantity=>1}]
        }

        obj.cart.forEach(item => {
            let product = {
                product_type_id: item.id,
                quantity: item.quantity ? item.quantity : 1
            }
            if (item.bay_id) {
                product.bay_id = item.bay_id
            }
            form.products.push(product)
        })
        let apiUrl = process.env.MIX_API_URL + '/reservations/' + obj.rental_id
        try {
            const resp = await axios.put(apiUrl, form)
            if (resp.status == 200) {
            }
            if (resp?.data) {
                commit('SET_APIFORM', resp.data)
            }
        }
        catch (err) {
            console.log('error', err.response?.data?.message)
            let errorMessage = err.response?.data?.message || ''
            if (typeof errorMessage === 'string' && err.response?.data?.message) {
                commit('SET_COUPON_MSG', err.response.data.message,)
            }
            else {
                commit('SET_ERROR_MSG', {
                    message: 'error',
                    page: "updateOrderError",
                })
            }
        }
    },
    async submitForm({ commit, dispatch }, form) { //Checkout
        commit('SET_CUSTOMER', form)
        // console.log(form)
        let apiUrl
        if (location.hostname.includes('rugdoctordelivery.com') || location.hostname.includes('delivery.rugdoctor.com') || location.hostname.includes('rugdoctorhomedelivery')) {
            apiUrl = process.env.MIX_API_URL + '/rentals/' // Home Delivery
        }
        else {
            apiUrl = process.env.MIX_API_URL + '/reservations/' //'/reservations/'
        }

        try {
            const resp = await axios.put(apiUrl + form.rental_id + '/publish', form)

            if (resp.status == 200) { // success
                // console.log(resp.status)
            }
            if (resp?.data) {
                commit('SET_CONFIRMATION', resp.data)
            }
        }
        catch (err) {
            if (err.response?.data?.message) {
                let errorObj = {
                    message: err.response.data.message,
                    page: "checkoutPageErrorMsg",
                }
                commit('SET_ERROR_MSG', errorObj)
            }
            console.error(err)
        }
    },
    async setPublishForm({ commit, dispatch }, form) {
        commit('SET_CONFIRMATION', form)
    },
    async setAPIForm({ commit, dispatch }, form) {
        commit('SET_APIFORM', form)
    },
    async setCustomer({ commit, dispatch }, customer) {
        commit('SET_CUSTOMER', customer)
    },
    async getHolidays({ commit, dispatch }, zip) { //Home Delivery
        let apiUrl = process.env.MIX_API_URL + '/holiday/holidays-by-zip?'
        let zipcode = zip ? zip : state.currentLocation.zipcode
        try {
            let res = await axios({
                url: apiUrl + 'zip=' + zipcode,
                method: 'get',
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (res?.data) {
                commit('SET_HOLIDAYS', res.data)
            }
        }
        catch (err) {
            console.error(err)
        }
    },
    async getTimeSlots({ commit, dispatch }, zip) { //Home Delivery
        let apiUrl = process.env.MIX_API_URL + '/time_slot/time-slots-by-zip?'
        let zipcode = zip ? zip : state.currentLocation.zipcode
        try {
            let res = await axios({
                url: apiUrl + 'zip=' + zipcode,
                method: 'get',
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (res?.data) {

                commit('SET_TIME_SLOTS', res.data)
            }
        }
        catch (err) {
            console.error(err)
        }
    },

}

export const getters = {
    // API
    affiliateId: (state) => {
        return state.affiliate_id
    },
    apiData: (state) => {
        return state.apiData
    },
    dataRes: (state) => {
        return state.dataRes
    },
    //Error Messages
    productPageErrorMsg: (state) => {
        return state.productPageErrorMsg
    },
    checkoutPageErrorMsg: (state) => {
        return state.checkoutPageErrorMsg
    },
    couponErrorMsg: (state) => {
        return state.couponErrorMsg
    },
    reservationsErrorMsg: (state) => {
        return state.reservationsErrorMsg
    },
    deliveryErrorMsg: (state) => {
        return state.deliveryErrorMsg
    },
    updateOrderError: (state) => {
        return state.updateOrderError
    },
    // CART
    itemCount: state => {
        return state.cartItems.length
    },
    cart: state => {
        return state.cartItems
    },
    cartItems: (state) => {
        return state.cartItems
    },

    // TABS
    tabs: (state) => {
        return state.tabs
    },
    currentIndex: (state) => {
        return state.currentIndex
    },
    currentTabIndex: (state) => {
        return state.currentTabIndex
    },
    // FORMS
    form: (state) => {
        return state.selectionForm
    },

    // DATES
    datesArray: (state) => {
        return state.datesArray
    },
    pickupDatesArray: (state) => {
        return state.pickupDatesArray
    },
    timeOfDayOptions: (state) => {
        return state.timeOfDayOptions
    },
    pickupTimeOfDayOptions: (state) => {
        return state.pickupTimeOfDayOptions
    },
    calendarToggle: (state) => {
        return state.calendarToggle
    },
    holidays: (state) => {
        return state.holidays
    },
    timeslots: (state) => {
        return state.timeslots
    },

    // ITEMS
    machineList: (state) => {
        return state.machineList
    },

    solutionList: (state) => {
        return state.solutionList
    },

    toolList: (state) => {
        return state.toolList
    },
    bundleList: (state) => {
        return state.bundleList
    },
    // LOCATION
    currentLocation: (state) => {
        return state.currentLocation
    },
    initialLoad: (state) => {
        return state.initialLoad
    },


    // CUSTOMER
    customer: (state) => {
        return state.customer
    },
    // CONFIRMATION
    confirmation: (state) => {
        return state.confirmation
    },
    jdeNumber: (state) => {
        return state.jdeNumber
    },
    kiosk_id: (state) => {
        return state.kiosk_id
    },
}


