<template>
    <TransitionRoot
        as="template"
        :show="showInputModal"
    >
        <Dialog
            as="div"
            class="fixed z-50 inset-0 overflow-y-auto"

        >
            <div class="flex items-end justify-center min-h-screen  px-8 lg:px-12 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="inline-block h-max text-left  transform transition-all ">
                        <div class="w-screen lg:w-full h-full items-center font-thin font-open_sans text-lg xl:text-fs_headline_div bg-white  p-6 lg:p-20 rounded-lg">
                            <!-- Close Button-->
                            <!-- <div class="w-full">
                                <span class="">
                                    <img
                                        @click.prevent="closePolicy()"
                                        class="z-10 object-contain h-[24px] w-[24px] ml-auto"
                                        src="../assets/close-line.png"
                                    />
                                </span>
                            </div> -->
                            <div class="flex flex-col justify-content-between">
                                    <div v-if="serviceType==='delivery'" class="lg:text-rd_fontsize-22  ">Please enter your ZIP Code</div>
                                    <div v-else class="lg:text-rd_fontsize-22  ">Please enter a JDE Number</div>
                                    <div class="w-full py-2 px-2 flex flex-col">
                                        <div class="w-full  flex flex-row">
                                            <input
                                                type="text"
                                                v-model="inputValue"
                                                :placeholder="[serviceType==='delivery' ? 'ENTER ZIP' : 'ENTER JDE']"
                                                class="text-rd_fontsize-8  md:text-rd_fontsize-12 block w-8/12 border hover:border-0 focus:ring-rd_red rounded-l-lg"
                                            />
                                            <div
                                                @click.prevent="handleSubmit"
                                                class="cursor-pointer block hover:bg-hover_red justify-center content-center  text-center  text-rd_fontsize-8  md:text-rd_fontsize-12 w-4/12 border border-rd_red text-white bg-rd_red py-3 rounded-r-lg"
                                            >
                                                Enter
                                            </div>
                                        </div>

                                    </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ref, watch } from 'vue'
    export default {
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
        },
        props: ['showInputModal', 'toggleInputModal', 'changeZipcode','displaySpinner','serviceType','changeJDE'],
        setup (){
            const inputValue = ref('')
            return {
                inputValue,
            }
        },
        mounted(){
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        },
        beforeUnmount(){
            document.documentElement.style.padding = null
            document.documentElement.style.overflow = null
        },
        methods: {
            handleSubmit() {
                if(this.serviceType === 'delivery'){
                    this.changeZipcode(this.inputValue)
                }
                else{
                    this.changeJDE(this.inputValue)
                }
                this.toggleInputModal()
                this.displaySpinner(true)
            },
        },
    }
</script>
